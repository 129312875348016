<script lang="ts">
	import { Accordion, AccordionItem } from '@skeletonlabs/skeleton';

	type FAQItem = {
		answer: string;
		question: string;
	};

	const FAQ_ITEMS: FAQItem[] = [
		{
			answer: 'Tickrr is a modern charting and market analysis platform for investors. It is your ultimate partner in intelligent investing, designed to help you drive your profits to the moon.',
			question: 'What is Tickrr?'
		},
		{
			answer: 'Tickrr sources high-quality market data from a multitude of the most reputable sources in the financial industry. Relying on this data, Tickrr provides a variety of tools to help you make better trading decisions, including charts, indicators, screeners, news feeds, and more.',
			question: 'How does Tickrr work?'
		},
		{
			answer: 'You can sign up for Tickrr by clicking on the "Start your free trial" button below, or by simply clicking <a class="anchor" href="/signup"><span>here</span></a>.',
			question: 'How do I sign up?'
		},
		{
			answer: 'No. Tickrr is a paid service. Charging for our product allows us to continue to provide you with the best possible service, and to ensure that you are always getting the most up-to-date and accurate stock market data.',
			question: 'Is Tickrr free?'
		},
		{
			answer: 'Yes. Tickrr offers a free trial for new users. You can <a class="anchor" href="/subscribe"><span>try Tickrr free for 14 days</span></a>, and then decide whether or not to continue using it.',
			question: 'Do you have a free trial?'
		},
		{
			answer: 'If you find yourself unsatisfied with Tickrr, please email us at <a class="anchor" href="mailto:support@tickrr.io"><span>support@tickrr.io</span></a>.',
			question: "What is Tickrr's refund policy?"
		},
		{
			answer: 'Yes. Tickrr supports all major cryptocurrencies, including Bitcoin, Ethereum, and more.',
			question: 'Are cryptocurrencies supported?'
		},
		{
			answer: 'Yes. Tickrr supports ETFs, including ETFs from the major exchanges.',
			question: 'Are ETFs supported?'
		},
		{
			answer: 'Not at the moment, but we will be adding support for mutual funds, options, and futures by the end of the year.',
			question: 'Are mutual funds and/or options supported?'
		},
		{
			answer: 'Yes. Tickrr supports after-hours data for all US-listed stocks and ETFs. However, please note that after-hours data is much more difficult to source and may not always be as accurate as regular trading hours data. We are working to find a higher-quality data provider specifically for after-hours data.',
			question: 'Is after-hours data available?'
		}
	];
</script>

<Accordion
	autocollapse
	hover="hover:bg-component-hover"
	padding="px-0 py-6 tablet:px-4"
	regionControl="text-xl border-t border-base"
	regionPanel="pt-0 pb-6 tablet:text-lg"
	rounded="rounded-none"
	spacing="space-y-0"
	width="w-full"
>
	{#each FAQ_ITEMS as item, idx (item)}
		<AccordionItem open={idx === 0}>
			<svelte:fragment slot="summary">
				<span class="font-bold text-surface-50">
					{item.question}
				</span>
			</svelte:fragment>
			<!-- eslint-disable-next-line svelte/no-at-html-tags -->
			<svelte:fragment slot="content">{@html item.answer}</svelte:fragment>
		</AccordionItem>
	{/each}
</Accordion>
